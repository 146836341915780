import React from "react"
import { Box, Image, Badge, Grid } from "@chakra-ui/core"
import { Link } from "gatsby"
import Layout from "../../components/layoutPhoenixCustom"
import SEO from "../../components/seo"
import BgImage from "../../images/our-leadership.jpg"
import Jackie from "../../images/surprise-staff/jackie.jpg"
import Ed from "../../images/support-staff/ed.jpg"
import Florence from "../../images/support-staff/florence.jpg"
import Andrew from "../../images/phoenix-staff/andrew.jpg"
import Josh from "../../images/phoenix-staff/josh.jpg"
import Dave from "../../images/phoenix-staff/dave.jpg"
import Donna from "../../images/phoenix-staff/donna-w.jpg"
import Kara from "../../images/phoenix-staff/kara.jpg"
import Karen from "../../images/phoenix-staff/karen.jpg"
import Matt from "../../images/phoenix-staff/matt.jpg"
import Scott from "../../images/phoenix-staff/scott.jpg"
import Debra from "../../images/support-staff/debra.jpg"
import Michael from "../../images/surprise-staff/michaelfox.jpg"
import Cory from "../../images/surprise-staff/corytafoya.jpg"
import Todd from "../../images/surprise-staff/ToddCarmichael.jpeg"
import Jim from "../../images/surprise-staff/jimgodfrey.jpg"
import Eugene from "../../images/surprise-staff/eugenejohnson.jpg"
import Caroll from "../../images/surprise-staff/carollkey.jpg"
import Chad from "../../images/surprise-staff/chad.jpg"
import Nathan from "../../images/surprise-staff/nathan.jpg"
import Lyle from "../../images/surprise-staff/LyleHarrenstein.jpeg"
import Garrett from "../../images/garrett.jpg"
import Jackson from "../../images/surprise-staff/JacksonNay.jpeg"
import Jordan from "../../images/phoenix-staff/jordan.jpg"
import DJ from "../../images/phoenix-staff/dj.jpg"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import PageHeaders from "../../components/pageHeaders"

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
`

function StaffBox({ imageUrl, imageAlt, name, title }) {
  return (
    <Box
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {name}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {title}
        </Box>
      </Box>
    </Box>
  )
}

const OurLeadership = () => (
  <Layout
    ImgSrc={BgImage}
    MainText="Our Leadership"
    SecondText="Staff here at Cross Church Phoenix"
  >
    <SEO title="Our Leadership - Phoenix" />
    <PageHeaders>About Us</PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      The Phoenix Campus was launched in March of 2016 as Cross Church’s second
      location and is the first campus to make us a multisite church. Prior to
      becoming a growing church campus, the property had seen 5 churches in 5
      years that had to close their doors.
    </ParagraphText>
    <HeaderText></HeaderText>
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
      ]}
      gap={10}
    >
      <StaffBox
        imageUrl={DJ}
        imageAlt="DJ Profile Pic"
        name="DJ Mathews"
        title="Campus Pastor"
      />
      <StaffBox
        imageUrl={Jordan}
        imageAlt="Jordan Profile Pic"
        name="Jordan Rowell"
        title="Worship Leader"
      />
      <StaffBox
        imageUrl={Donna}
        imageAlt="Donna Profile Pic"
        name="Donna Woldanski"
        title="Preschool Ministry Lead"
      />
      <StaffBox
        imageUrl={Karen}
        imageAlt="Karen Profile Pic"
        name="Karen Barnes"
        title="Community Garden Director"
      />
    </Grid>
    <PageHeaders
      css={css`
        margin-top: 6rem;
      `}
    >
      Cross Church Central Support
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText></ParagraphText>
    <HeaderText></HeaderText>
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
      ]}
      gap={10}
    >
      <StaffBox
        imageUrl={Jackie}
        imageAlt="Jackie's Profile Pic"
        name="Jackie Allen"
        title="Lead Pastor"
      />
      <StaffBox
        imageUrl={Chad}
        imageAlt="Chad's Profile Pic"
        name="Chad Kritenbrink"
        title="Executive Pastor"
      />
      <StaffBox
        imageUrl={Debra}
        imageAlt="Debra's Profile Pic"
        name="Debra Toles"
        title="Cross Church Administrator"
      />
      <StaffBox
        imageUrl={Ed}
        imageAlt="Ed's Profile Pic"
        name="Ed Wales"
        title="Facilities Manager"
      />
      <StaffBox
        imageUrl={Florence}
        imageAlt="Florence's Profile Pic"
        name="Florence Brown"
        title="Financial Administrative Assistant"
      />
    </Grid>
    <PageHeaders
      css={css`
        margin-top: 6rem;
      `}
    >
      Cross Church Elders
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText></ParagraphText>
    <HeaderText></HeaderText>
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
      ]}
      gap={10}
    >
      <StaffBox
        imageUrl={Jim}
        imageAlt="Jim's Profile Pic"
        name="Jim Godfrey"
        title="Elder"
      />
      <StaffBox
        imageUrl={Michael}
        imageAlt="Michael's Profile Pic"
        name="Michael Fox"
        title="Elder"
      />
      <StaffBox
        imageUrl={Jackson}
        imageAlt="Jackson's Profile Pic"
        name="Jackson Nay"
        title="Elder"
      />
      <StaffBox
        imageUrl={Lyle}
        imageAlt="Lyle's Profile Pic"
        name="Lyle Harrenstein"
        title="Elder"
      />
    </Grid>
  </Layout>
)

export default OurLeadership
